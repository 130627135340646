.arrow-input {
    width: 4.5rem;
    min-width: 4.5rem;
}

.arrow-input > input {
    text-align: right;
}

.arrow-input-p {
    margin: 0 12px;
}

.a-input-number-arrow .a-input-number {
    padding: 0 1.25rem 0 0.25rem;
}

.line-item-label {
    padding-right: 8px;
    white-space: nowrap;
}

.p-v-8 {
    padding-bottom: 8px;
    padding-top: 8px;
}

.footer-text {
    background-color: #000000;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
}

.footer-text a:hover,
.footer-text a:visited:hover {
    color: #d04a02;
}

.font-header {
    font-size: 24px;
}

.pwc-logo {
    width: 58px;
}

.m-h-16 {
    margin-left: 16px;
    margin-right: 16px;
}

.p-t-32 {
    padding-top: 32px;
}

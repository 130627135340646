.toaster {
    position: fixed !important;
    top: 0;
    left: 0;
}

.toaster.toaster-red {
    background-color: #c52a1a;
}

.a-banner-wrap-div {
    position: fixed !important;
}

.banner-a {
    text-decoration: underline !important;
}

.banner-a:hover {
    color: #7d7d7d !important;
}
body {
    margin: 0;
    font-family: "PwC Helvetica Neue";
    font-weight: normal;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover,
a:visited:hover {
    color: black;
}

:root {
    --bs-body-color: #2d2d2d;
}

/* keep the footer at the bottom of the page */
#root {
    position: relative;
    min-height: 100vh;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 52px;
}

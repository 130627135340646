.renewal-red {
    color: #c52a1a;
}
.renewal-green {
    color: #22992e;
}
.w-380 {
    width: 380px !important;
}
.w-80 {
    width: 80px !important;
}
.w-176 {
    width: 176px !important;
}
.w-384 {
    width: 384px !important;
}
.w-400 {
    width: 400px;
}
.w-517 {
    width: 517px;
}
.w-588 {
    max-width: 588px !important;
}

.p-t-20 {
    padding-top: 20px;
}
.p-t-44 {
    padding-top: 44px;
}
.p-b-66 {
    padding-bottom: 66px;
}
.p-b-15 {
    padding-bottom: 15px;
}
.p-b-80 {
    padding-bottom: 80px;
}
.p-l-12 {
    padding-left: 12px;
}
.p-v-24 {
    padding: 24px 0;
}

.m-t-4 {
    margin-top: 4px;
}
.m-t-19 {
    margin-top: 19px;
}
.m-t-27 {
    margin-top: 27px;
}
.m-t-46 {
    margin-top: 46px;
}
.m-b-10 {
    margin-bottom: 10px;
}
.m-b-19 {
    margin-bottom: 19px;
}
.m-b-20 {
    margin-bottom: 20px;
}
.m-b-80 {
    margin-bottom: 80px;
}
.m-l-8 {
    margin-left: 8px;
}
.m-l-14 {
    margin-left: 14px;
}
.m-v-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}
.m-v-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}
.m-v-16 {
    margin: 0 16px;
}
.m-v-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.clear-border-bottom {
    border-bottom: none !important;
}
.clear-p-v {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.clear-p-r {
    padding-right: 0;
}
.clear-p-l {
    padding-left: 0;
}

.withdraw-modal .modal-content,
.discard-modal .modal-content {
    border-radius: 24px !important;
    padding: 16px;
}

span.required::before {
    content: "*";
    color: "#2D2D2D";
}

.flex-justify-between {
    display: flex;
    justify-content: space-between;
}

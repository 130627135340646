.steps {
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
}

.step-holder:first-child,
.step-holder-active:first-child {
    margin-left: 0;
}

.step-holder:first-child::after,
.step-holder-active:first-child::after {
    display: none;
}

.step-holder,
.step-holder-active {
    position: relative;
    margin-left: 71px;
}

.step-holder::after,
.step-holder-active::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 69px;
    margin-left: -1px;
    transform: translate(-100%, -50%);
    left: 0;
    top: 50%;
}

.step-holder::after {
    background-color: #999999;
}

.step-holder-active::after {
    background-color: #d04a02;
}

.step-icon {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    background-color: #999999;
    position: relative;
}

.step-icon img {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}

.step-label {
    margin-top: 10px;
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    font-weight: bold;
    color: #999999;
}

.active-icon {
    background-color: #d04a02;
}

.active-label {
    color: #d04a02;
}

.support-border {
    border-radius: 21px !important;
    border-color: #d04a02 !important;
    border: 1px solid #d04a02 !important;
    color: #d04a02 !important;
    padding: 4px 10px 3px 6px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.support-banner {
    position: fixed;
    bottom: 75px;
    right: 120px;
}

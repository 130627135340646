.flex-justify-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content > div {
    width: 384px;
}

.max-w-588 {
    max-width: 588px !important;
}

.p-l-12 {
    padding-left: 12px !important;
}

.p-t-2 {
    padding-top: 2px;
}

.p-t-4 {
    padding-top: 4px;
}

.p-t-6 {
    padding-top: 6px;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-24 {
    padding-top: 24px;
}

.p-t-28 {
    padding-top: 28px;
}

.p-t-36 {
    padding-top: 36px;
}

.p-t-64 {
    padding-top: 64px;
}

.p-b-8 {
    padding-bottom: 8px;
}

.p-b-10 {
    padding-bottom: 10px;
}

.p-b-16 {
    padding-bottom: 16px;
}

.p-b-24 {
    padding-bottom: 24px;
}

.p-b-28 {
    padding-bottom: 28px;
}

.p-b-29 {
    padding-bottom: 29px;
}

.p-b-36 {
    padding-bottom: 36px;
}

.p-b-45 {
    padding-bottom: 45px;
}

.p-b-48 {
    padding-bottom: 48px;
}

.p-b-125 {
    padding-bottom: 125px;
    background-clip: border-box;
}

.p-b-185 {
    padding-bottom: 185px;
}

.p-v-12 {
    padding: 12px 0;
}

.p-v-18 {
    padding: 18px 0;
}

.p-v-80 {
    padding: 80px 0;
}

.p-r-8 {
    padding-right: 8px;
}

.p-r-16 {
    padding-right: 16px;
}

.p-h-18 {
    padding: 0 18px;
}

.m-l-6 {
    margin-left: 6px;
}

.m-r-8 {
    margin-right: 8px;
}

.m-r-16 {
    margin-right: 16px;
}

.m-t-4 {
    margin-top: 4px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-48 {
    margin-top: 48px;
}

.m-t-104 {
    margin-top: 104px;
}

.m-b-12 {
    margin-bottom: 12px;
}

.m-b-24 {
    margin-bottom: 24px;
}

.m-b-38 {
    margin-bottom: 38px;
}

.m-b-52 {
    margin-bottom: 52px;
}

.m-v-8 {
    margin: 8px 0;
}

.m-v-16 {
    margin: 16px 0;
}

.m-v-24 {
    margin: 24px 0;
}

.light-orange {
    color: #e45c2b;
}

.orange {
    color: #d04a02;
}

.black {
    color: black;
}

.afs-error {
    padding: 9px 16px;
    border-radius: 2px;
}

.round-card,
.round-card-black {
    border-radius: 24px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.round-card {
    padding: 28px 36px;
}

.round-card-black-top {
    padding: 36px 24px 16px 24px;
}

.black-background,
.black-background-rounded {
    color: white;
    /* background-clip: border-box; */
    background-color: black;
    padding: 16px 24px 16px 24px;
}

.black-background-rounded {
    border-radius: 0px 0px 24px 24px;
}

.separator-line {
    height: 1px;
    border-bottom: 1px solid #dedede;
    margin-top: 20px;
    margin-bottom: 20px;
}

.separator-line-b-m-24 {
    height: 1px;
    border-bottom: 1px solid #dedede;
    margin-bottom: 24px;
}

.third-bold {
    font-weight: 300;
}

.half-bold {
    font-weight: 500;
}

.gray-text-10,
.gray-text-12,
.gray-text-14,
.gray-text-16,
.gray-text-18,
.gray-text-22,
.gray-text-32,
.gray-text-45 {
    color: #2d2d2d;
}

.light-gray,
.light-gray-text-12,
.light-gray-text-14,
.light-gray-text-16,
.light-gray-text-18 {
    color: #7d7d7d;
}

.lighter-gray-text-16 {
    color: #dedede;
}

.gray-text-10,
.text-10 {
    font-size: 10px;
    letter-spacing: 0;
    line-height: 15px;
}

.text-12,
.gray-text-12,
.light-gray-text-12 {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 19px;
}

.text-14,
.gray-text-14,
.light-gray-text-14 {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
}

.text-16,
.gray-text-16,
.light-gray-text-16,
.lighter-gray-text-16 {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}

.text-18,
.gray-text-18,
.light-gray-text-18 {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
}

.text-22,
.gray-text-22 {
    font-size: 22px;
    letter-spacing: 0;
    line-height: 36px;
}

.gray-text-32 {
    font-size: 32px;
    letter-spacing: 0;
    line-height: 48px;
}

.gray-text-45 {
    font-size: 45px;
}

.gray-background {
    background-color: #f2f2f2;
}

.nav-buttons {
    text-align: right;
}

.nav-buttons > button {
    margin-left: 24px;
}

.nav-buttons > .a-btn,
.padded-button {
    padding: 25px !important;
    font-size: 16px !important;
}

/* the no interaction state removes the border which shifts the button 2px */
.nav-buttons > .a-btn-no-interaction {
    padding: 23px 25px 27px 25px !important;
}

.clickable {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

.select-toggle {
    background-color: #f2f2f2 !important;
}

.modal-backdrop {
    background: rgba(0, 0, 0, 0.9) !important;
}

.waive-modal {
    max-width: 600px !important;
}

.waive-modal ul,
.waive-modal li {
    list-style: disc !important;
    list-style-position: inside !important;
}

.waive-modal > div {
    padding: 20px 10px;
    border-radius: 24px !important;
    box-shadow: 0 7px 18px 1px rgba(0, 0, 0, 0.16) !important;
}

.a-loading.a-loading-14 {
    height: 0.875rem;
    width: 0.875rem;
}

.review-icon {
    display: inline-block;
    height: 36px !important;
    width: 36px !important;
}

.review-icon-container {
    display: flex;
    align-items: center;
}

.calendar-picker-w-100 > div,
.calendar-picker-w-100 > div > div {
    width: 100% !important;
}

.wrapped-calendar-picker-w-100 > div > div > div,
.wrapped-calendar-picker-w-100 > div > div > div > div {
    width: 100% !important;
}

/* fixes "September YYYY" wrapping on the small calendar */
.react-calendar .r-c-nav-bar .a-center-button-wrapper {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}

.col-account-icon {
    flex: 0 0 auto;
    width: 17% !important;
}

.col-account-text {
    flex: 0 0 auto;
    width: 83% !important;
}

.col-afs-icon {
    flex: 0 0 auto;
    width: 10% !important;
}

.col-afs-text {
    flex: 0 0 auto;
    width: 90% !important;
}

.calendar-right-align .react-date-picker--open {
    left: 175px !important;
}

.a-calendar.a-input-error > div > div {
    border: 0;
    box-shadow: 0 0 0 0.125rem #d04a02;
    border-radius: 0.125rem;
}

.a-modal-opened {
    overflow: auto !important;
}

.hide-buttons > button {
    display: none !important;
}

.hide-buttons > input {
    padding: 0 !important;
}

.input-number-center > input {
    text-align: center;
}

.module-list {
    margin: 0;
    padding: 0 0 2px 12px;
}

.module-list > li {
    list-style-type: disc;
    padding-left: 10px;
}

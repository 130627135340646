.text-pad {
    padding-top: 32px;
}
.column-pad {
    padding-top: 20px;
}
.w-261 {
    width: 261px;
}
.m-b-144 {
    margin-bottom: 144px;
}

.modal-title {
    height: 36px;
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 36px;
}

.modal-description-long {
    height: 126px;
    margin-bottom: 10px;
}

.modal-description {
    height: 63px;
}

.modal-description,
.modal-description-long {
    padding-top: 10px;
    color: #2d2d2d;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    white-space: normal !important;
}

.terminate-modal .modal-content {
    border-radius: 24px !important;
    padding: 16px;
    width: 600px;
}

.offline-contracting-modal .modal-content {
    border-radius: 24px !important;
    padding: 16px;
    width: 600px;
}

.offline-contracting-modal .modal-content .modal-header {
    border-bottom: none !important;
}

.modal-separator-line {
    height: 1px;
    border-bottom: 1px solid #979797;
    margin-top: 20px;
    margin-bottom: 20px;
}

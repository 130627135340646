.pad-header {
    padding-top: 32px;
    padding-bottom: 32px;
}

.pad-logo {
    padding-bottom: 16px;
}

.border-bottom {
    border-bottom: #979797 10px solid;
}
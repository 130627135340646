.fade-height.collapsed,
.fade-height.collapsed > * {
  opacity: 0;
}

.fade-height.collapsed,
.fade-height.collapsed * {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 1s ease-out, opacity 0.6s ease-out;
  animation: 1s delay-overflow;
}

@keyframes delay-overflow {
  from {
    overflow: visible;
  }
}

.fade-height.expanded,
.fade-height.expanded > * {
  opacity: 1;
  overflow: visible;
}

.fade-height.expanded,
.fade-height.expanded * {
  max-height: 400px;
  transition: max-height 1s ease-in, opacity 0.6s ease-in;
}

.spacer-64 {
  height: 64px;
}

.buttonCenter {
  white-space: nowrap;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  border-radius: 0%;
  white-space: nowrap;
  padding: 10px;
}

.gray-background {
    background-color: #F2F2F2;
}

.separator-line {
    height: 1px;
    border-bottom: 1px solid #979797;
    margin-top: 16px;
    margin-bottom: 32px;
}

.m-t-12 {
    margin-top: 12px;
}

.m-t-24 {
    margin-top: 24px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-32 {
    margin-top: 32px;
}

.m-t-36 {
    margin-top: 36px;
}

.m-t-38 {
    margin-top: 38px;
}

.m-t-40 {
    margin-top: 40px;
}

.m-b-8 {
    margin-bottom: 8px;
}

.m-b-12 {
    margin-bottom: 12px;
}

.m-b-16 {
    margin-bottom: 16px;
}

.m-b-18 {
    margin-bottom: 18px;
}

.m-b-24 {
    margin-bottom: 24px;
}

.m-b-26 {
    margin-bottom: 26px;
}

.m-b-32 {
    margin-bottom: 32px;
}

.m-b-36 {
    margin-bottom: 36px;
}

.m-b-44 {
    margin-bottom: 44px;
}

.m-b-224 {
    margin-bottom: 224px;
}

.m-v-3 {
    margin-top: 3px;
    margin-bottom: 3px;
}

.m-v-4 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.m-v-18 {
    margin-top: 18px;
    margin-bottom: 18px;
}

.m-v-32 {
    margin-top: 32px;
    margin-bottom: 32px;
}

.m-v-48 {
    margin-top: 48px;
    margin-bottom: 48px;
}

.m-l-12 {
    margin-left: 12px;
}

.m-r-18 {
    margin-right: 18px;
}

.m-r-24 {
    margin-right: 24px;
}

.p-r-10 {
    padding-right: 10px;
}

.p-v-32 {
    padding-top: 32px;
}

.p-b-32, .p-v-32 {
    padding-bottom: 32px;
}

.w-100 {
    width: 100%;
}

.italic {
    font-style: italic;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.orange {
    color: #D04A02;
}

.red {
    color: #E0301E;
}

.bold {
    font-weight: bold;
}

.pointer {
    cursor: pointer;
}

.round-card {
    border-radius: 24px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.16);
    padding: 28px 36px;
}

.title {
    border-radius: 12px;
    background-color: #DEDEDE;
    display: inline-block;
    padding: 3px 10px;
    font-weight: bold;
    color: #2D2D2D;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
}
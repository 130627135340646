.red-separator-line {
    border-bottom: 5px solid #d04a02 !important;
    margin: 30px 0;
}

.gray-separator-line {
    border-bottom: 1px solid #DEDEDE;
    margin-bottom: 24px;
}

.v-middle {
    vertical-align: middle;
}

.p-l-8 {
    padding-left: 8px;
}

.p-t-8 {
    padding-top: 16px;
}

.p-r-24 {
    padding-right: 24px !important;
}

.m-b-28 {
    margin-bottom: 28px;
}

.m-t-0 {
    margin-top: 0 !important;
}
.banner-text {
    background-color: #f2f2f2;
    color: #1a1919;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 16px;
    text-align: center;
    padding-bottom: 110px;
}

.banner-text a:hover,
.banner-text a:visited:hover {
    color: #d04a02;
}

.a-decorated {
    text-decoration: underline;
    color: #d04a02;
}
.p-t-40 {
    padding-top: 40px;
}

.w-588 {
    max-width: 588px !important;
}
.w-517 {
    width: 517px;
}
.p-b-75 {
    padding-bottom: 75px;
}
.green-fix {
    color: #22992e !important;
}
.success-button {
    width: 141px !important;
    height: 50px !important;
}
.wide-success-button {
    width: 170px !important;
    height: 50px !important;
}

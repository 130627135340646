.m-checkbox {
    margin: 8px 16px 24px 0px;
}

.m-checkbox-agree {
    margin: 4px 16px 0 0;
}

.fade-section.collapsed,
.fade-section.collapsed > * {
    opacity: 0;
    max-height: 0px;
}

.fade-section.collapsed,
.fade-section.collapsed * {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.4s ease-out, opacity 0.4s ease-out;
    animation: 1s delay-overflow;
}

@keyframes delay-overflow {
    from {
        overflow: visible;
    }
}

.fade-section.expanded,
.fade-section.expanded > * {
    opacity: 1;
    max-height: 3000px;
}

.fade-section.expanded,
.fade-section.expanded * {
    max-height: 3000px;
    transition: max-height 1s ease-out, opacity 0.6s ease-out;
    overflow: visible;
}

.subtotal {
    height: 2.125rem;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.125rem;
    background-color: #f2f2f2;
    color: #2d2d2d;
    padding: 0 0.625rem;
    font-size: 0.875rem;
    border: 0.0625rem solid #dedede;
    text-align: right;
    padding-top: 0.35rem;
}

.subtotal,
.subtotal-w {
    width: 8.375rem;
    min-width: 8.375rem;
}

.p-h-3 {
    padding: 0 3px;
}

.submit-button {
    height: 3.125rem;
    width: 7rem;
}

.dark-spinner {
    height: 2rem;
}

.confirm {
    margin-left: 3px;
    margin-right: 15px;
    vertical-align: middle;
}

.location-icon {
    margin-left: -12px;
}

.underline {
    text-decoration: underline;
}

.full-screen-modal {
    z-index: 99999999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.full-screen-modal-content {
    text-align: center;
    padding-top: 15%;
    color: white;
}
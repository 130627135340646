.a-pagination .a-page-link {
    color: #d04a02 !important;
}

.p-b-64 {
    padding-bottom: 64px;
}

.p-v-128 {
    padding: 128px 0;
}

.rt-td {
    align-items: flex-start !important;
    white-space: normal !important;
}

.rt-thead .rt-th {
    white-space: normal !important;
}

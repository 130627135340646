.us-t-c-content .small-caps {
    font-variant-caps: small-caps;
}

.text-underline {
    text-decoration: underline;
}

.t-c-modal {
    max-width: 65% !important;
}

.t-c-modal-title {
    border-bottom: 1px solid #979797 !important;
    min-height: 3.5rem;
}

.t-c-modal-body a,
.t-c-modal-body .color-blue {
    color: blue;
}

.t-c-modal-body .footer a {
    text-decoration: none;
    color: black;
}

.t-c-modal-body .color-orange {
    color: orange!important;
}

.t-c-modal-body div ol {
    padding-left: 24px;
}

.t-c-modal-body div ol li {
    display: block;
}

.t-c-modal-body div ol>li:first-child {
    counter-reset: item;
}

.t-c-modal-body div ol>li {
    counter-increment: item;
    position: relative;
}

.t-c-modal-body div ol>li:before {
    content: counters(item, ".") " ";
    position: absolute;
    margin-right: 100%;
    right: 5px;
}

.us-t-c-content .small-caps {
    font-variant-caps: small-caps;
}

.us-t-c-content ol>li::before {
    font-weight: bold;
}

.t-c-modal-body .no-list-item:before {
    content: '';
}

.t-c-modal-body li ul li {
    list-style-type: disc;
    display: list-item;
}

.t-c-modal-body .align-right {
    text-align: right;
}

.t-c-modal-body .align-center {
    text-align: center;
}

.text-underline {
    text-decoration: underline;
}

.t-c-modal-body ul {
    list-style: none;
    padding-left: 0;
}

.t-c-modal-body li {
    margin: 8px 0;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.t-c-modal-body a {
    text-decoration: underline;
}

.bg-color-yellow {
    background: yellow;
}
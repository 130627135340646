.tooltip-label {
    display: flex;
    margin-bottom: 12px;
}

.tooltip-label>label {
    padding-top: 4px;
    margin-right: 8px;
}

.tooltip-content {
    max-width: 216px;
    text-align: center;
}
.management-table {
    background: #ffffff !important;
    padding: 20px 0 40px 0;
    background-color: #ffffff !important;
    font-size: 14px;
    color: #2d2d2d;
}

.warning-text {
    font-size: 12px;
    padding-top: 15px;
}

.a-tab-bar {
    margin-bottom: 5px !important;
}

.rt-tr.-even {
    background-color: white !important;
}

.rt-tr-group {
    border-bottom-width: 2px !important;
    border-bottom-style: solid !important;
    border-bottom-color: #f2f2f2 !important;
    margin-top: 5px !important;
}

.rt-tr.-odd {
    background-color: #ffffff !important;
}

.rt-thead.-header {
    background-color: #f2f2f2 !important;
}

.submit-afs-button {
    min-width: 135px !important;
}

.submit-to-client-button {
    min-width: 160px !important;
}

.renew-button {
    min-width: 140px !important;
}

.submit-afs-button,
.submit-to-client-button,
.renew-button {
    white-space: nowrap !important;
}

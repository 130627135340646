.error {
    opacity: 0;
    transition: opacity 0.7s, visibility 0.7s !important;
    visibility: hidden;
}

.error.hide {
    opacity: 0;
    visibility: hidden;
}

.error.show {
    opacity: 1;
    visibility: visible;
}

.m-v-2 {
    margin: 0 2px !important;
}

.flex-justify-center {
    display: flex;
    justify-content: center;
}
.flex-justify {
    display: flex;
    justify-content: center;
}
.flex-align-center {
    display: flex;
    align-items: center;
}
.flex-justify-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex-start {
    display: flex;
    align-items: flex-start;
    padding-left: 0;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.flex {
    display: flex;
}
.bg-lighter {
    background-color: #f2f2f2;
}

.m-b-24 {
    margin-bottom: 24px;
}

.m-b-7 {
    margin-bottom: 7px;
}
.m-b-125 {
    margin-bottom: 125px;
}

.p-v-48 {
    padding: 48px 0;
}

.m-l-24 {
    margin-left: 24px;
}
.m-l-15 {
    margin-left: 15px;
}
.m-r-6 {
    margin-right: 6px;
}

.m-v-24 {
    margin-top: 24px;
    margin-bottom: 24px;
}

.orange {
    color: #d04a02 !important;
}
.darker {
    color: #2d2d2d;
}
.white {
    color: #ffffff;
}
.gray {
    color: #7d7d7d;
}
.green {
    color: #2c8646;
}

.round-card {
    width: 100%;
    border-radius: 24px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    height: auto;
    padding: 24px;
}

.bg-white {
    background-color: #ffffff;
}

.bg-black {
    background-color: #000000;
}

.line-height-16 {
    line-height: 16px;
}

.a-hover:hover {
    color: #d04a02 !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.underline {
    text-decoration: underline !important;
}

.dod-bottom-line {
    border-bottom: 1px #979797 solid;
    padding-bottom: 25px;
}

.text-bold {
    font-weight: bold;
}

.pa-0 {
    padding: 0;
}
.m-h-24 {
    margin-left: 24px;
    margin-right: 24px;
}
.p-t-26 {
    padding-top: 26px;
}
.p-t-15 {
    padding-top: 15px;
}
.clear-margin-bottom {
    margin-bottom: 0 !important;
}
.clear-margin-top {
    margin-top: 0 !important;
}
.p-h-24 {
    padding-left: 24px;
    padding-right: 24px;
}
.p-v-24 {
    padding-top: 24px;
    padding-bottom: 24px;
}
.p-v-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}
.m-t-15 {
    margin-top: 15px;
}
.m-b-28 {
    margin-bottom: 28px;
}
.m-t-80 {
    margin-top: 80px;
}
.m-b-30 {
    margin-bottom: 30px;
}
.border-radius-b {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}
.m-t-16 {
    margin-top: 16px;
}
.text-justify {
    text-align: justify;
}
.one-time-fee ul li {
    display: list-item;
    list-style-type: disc;
}

.p-l-85-em {
    padding-left: 0.85rem;
}

.one-time-fee ul li.clear-li-style {
    list-style-type: none;
    padding-left: 0;
}
.one-time-fee ul {
    padding-left: 0.85rem;
}
.ul-clear-left-p {
    padding-left: 0 !important;
}
.circle-36 {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #d04a02;
    display: flex;
    align-items: center;
    justify-content: center;
}

.m-t-18 {
    margin-top: 18px;
}
.m-t-12 {
    margin-top: 12px;
}

.seperate-line {
    height: 1px;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 24px;
    background-color: #bdbdbd;
}
.text-right {
    text-align: right;
}
.p-b-38 {
    padding-bottom: 38px;
}
.a-loading.a-loading-16 {
    height: 1rem;
    width: 1rem;
}
.w-130 {
    width: 170px;
}
.h-34 {
    height: 34px;
}
.reset-checkbox .a-checkbox-wrapper {
    align-items: flex-start;
}
.reset-checkbox .a-checkbox-wrapper .a-checkbox-inner {
    top: 0 !important;
    transform: none !important;
}

.reset-checkbox p {
    margin-top: 0 !important;
}

.img-order-summary {
    margin: 15px 48px 15px 64px;
}
.min-w-left {
    min-width: 430px;
}
.min-w-right {
    min-width: 330px;
}
.font-normal {
    font-weight: normal;
}
.w-589 {
    width: 589px;
}
.w-558 {
    width: 558px;
}
.w-500 {
    width: 500px;
}
.w-342 {
    width: 342px;
}
.m-b-300 {
    margin-bottom: 300px;
}
.m-b-58 {
    margin-bottom: 58px;
}
.dod-card-title a {
    text-decoration: none !important;
}
.dod-card-title a:hover {
    text-decoration: underline !important;
}
.m-t-59 {
    margin-top: 59px;
}
.m-b-197 {
    margin-bottom: 197px;
}
.clear-m-b {
    margin-bottom: 0 !important;
}
.clear-m-t {
    margin-top: 0 !important;
}
.transfer-to-block {
    display: inline-block;
}
.word-break {
    word-break: break-word;
}
.nowrap {
    white-space: nowrap;
}
.payment-terms-CN {
    color: #2d2d2d;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    display: inherit;
    line-height: 15px;
}

.w-percent-70 {
    width: 70%;
}
